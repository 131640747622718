//export const base_url = "https://127.0.0.1:80032/";
//export const base_url_api = "https://127.0.0.1:8002/api";
export const base_url = "https://api.fibme.fr/";
export const base_url_api = "https://api.fibme.fr/api";
export const document_type_devis = "devis";
export const document_type_fiche_intervention = "fiche_intervention";
export const document_type_order_travail = "order_travail";
export const document_type_bon_intervention = "bon_intervention";
export const document_type_facture = "facture";

export const STRIPE_URL = base_url;
export const STRIPE_KEY =
  "pk_test_51OkSicLWmujGDCDVsKuNWQXsswBXDb7zrmttXFIpj2xNMSsfLVP0evr8FARnLQ0kD2AyQuOKaqRZNUwQkp5dy2Dr00IC2lAa3F";

export const rdv_type_call = "rdv_call";
export const rdv_type_prestation = "rdv_prestation";

export const limitPagination = 10;
export const status_prestation_tout = "tout";
export const status_prestation_en_cours = "en_cours";
export const status_prestation_terminer = "terminer";
export const status_prestation_a_venir = "a_venir";
export const status_prestation_urgent = "urgent";

export const STATUS_PENDING = "pending";
export const STATUS_SIGNED = "signed";
export const STATUS_REFUSER = "refuser";
export const STATUS_REJECTED = "rejected";

export const list_metiers = [
  "Technicien FTTH",
  "Electricien courant faible",
  "Peintre",
  "Artisan maçon",
  "Génie civil",
];
export const list_dispos = [
  { id: "1", value: "7/7" },
  { id: "2", value: "Journée uniquement" },
  { id: "3", value: "Soir et week-end" },
];

export const obj = [
  { type: "salarié(e)", key: "salarié(e)" },
  { type: "entrepreneur", key: "entrepreneur" },
];

export const problems = [
  { value: "débutant(e)", label: "débutant(e)" },
  { value: "niveau moyen", label: "niveau moyen" },
  { value: "professionnel", label: "professionnel" },
];

export const hoursList = [
  { value: "08:00", label: "08:00" },
  { value: "08:30", label: "08:30" },
  { value: "09:00", label: "09:00" },
  { value: "09:30", label: "09:30" },
  { value: "10:00", label: "10:00" },
  { value: "10:30", label: "10:30" },
  { value: "11:00", label: "11:00" },
  { value: "11:30", label: "11:30" },
  { value: "12:00", label: "12:00" },
  { value: "12:30", label: "12:30" },
  { value: "13:00", label: "13:00" },
  { value: "13:30", label: "13:30" },
  { value: "14:00", label: "14:00" },
  { value: "14:30", label: "14:30" },
  { value: "15:00", label: "15:00" },
  { value: "15:30", label: "15:30" },
  { value: "16:00", label: "16:00" },
  { value: "16:30", label: "16:30" },
  { value: "17:00", label: "17:00" },
  { value: "17:30", label: "17:30" },
  { value: "18:00", label: "18:00" },
  { value: "18:30", label: "18:30" },
  { value: "19:00", label: "19:00" },
  { value: "19:30", label: "19:30" },
];

export const hours = [
  "08:00",
  "08:30",
  "09:00",
  "09:30",
  "10:00",
  "10:30",
  "11:00",
  "11:30",
  "12:00",
  "12:30",
  "13:00",
  "13:30",
  "14:00",
  "14:30",
  "15:00",
  "15:30",
  "16:00",
  "16:30",
  "17:00",
  "17:30",
  "18:00",
  "18:30",
  "19:00",
  "19:30",
];

export const months = [
  { value: "01", label: "JAN" },
  { value: "02", label: "FEB" },
  { value: "03", label: "MAR" },
  { value: "04", label: "APR" },
  { value: "05", label: "MAY" },
  { value: "06", label: "JUN" },
  { value: "07", label: "JUL" },
  { value: "08", label: "AUG" },
  { value: "09", label: "SEP" },
  { value: "10", label: "OCT" },
  { value: "11", label: "NOV" },
  { value: "12", label: "DEC" },
];

export const years = [
  { value: "2000", label: "2000" },
  { value: "2001", label: "2001" },
  { value: "2002", label: "2002" },
  { value: "2003", label: "2003" },
  { value: "2004", label: "2004" },
  { value: "2005", label: "2005" },
  { value: "2006", label: "2006" },
  { value: "2007", label: "2007" },
  { value: "2008", label: "2008" },
  { value: "2009", label: "2009" },
  { value: "2010", label: "2010" },
  { value: "2011", label: "2011" },
  { value: "2012", label: "2012" },
  { value: "2013", label: "2013" },
  { value: "2014", label: "2014" },
  { value: "2015", label: "2015" },
  { value: "2016", label: "2016" },
  { value: "2017", label: "2017" },
  { value: "2018", label: "2018" },
  { value: "2019", label: "2019" },
  { value: "2020", label: "2020" },
  { value: "2021", label: "2021" },
  { value: "2022", label: "2022" },
  { value: "2023", label: "2023" },
  { value: "2024", label: "2024" },
];

export const status_demande = [
  {
    id: "Préplanifier",
    value: "Préplanifier",
    label: "Préplanifier",
    labelle: "Préplanifier",
  },
  {
    id: "Planifier",
    value: "Planifier",
    label: "Planifier",
    labelle: "Planifier",
  },
  {
    id: "Déplanifier",
    value: "Déplanifier",
    label: "Déplanifier",
    labelle: "Déplanifier",
  },
  {
    id: "Annuler",
    value: "Annuler",
    label: "Annuler",
    labelle: "Annuler",
  },
];

export const phase_demande = [
  {
    id: 7,
    value: "7",
    label: "Intervention en cours",
    labelle: "Intervention en cours",
  },
  {
    id: 8,
    value: "8",
    label: "Intervention terminer",
    labelle: "Intervention terminer",
  },
  {
    id: 11,
    value: "11",
    label: "Paiement confirmer",
    labelle: "Paiement confirmer",
  },
];

export const list_phase_demande = [
  {
    id: 1,
    label: "Inscription client",
  },
  {
    id: 2,
    label: "Attribution partenaire",
  },
  {
    id: 3,
    label: "Création du devis",
  },
  {
    id: 4,
    label: "Devis Envoyer",
  },
  {
    id: 5,
    label: "Devis Accepter, Planification",
  },
  {
    id: 6,
    label: "Intervention planifier",
  },
  {
    id: 7,
    label: "Intervention en cours",
  },
  {
    id: 8,
    label: "Intervention terminer",
  },
  {
    id: 9,
    label: "FI signer par le partenaire",
  },
  {
    id: 10,
    label: "FI signer par le client",
  },
  {
    id: 11,
    label: "Paiement confirmer",
  },
];

export const etat_demande = [
  {
    id: "Clos",
    value: "Clos",
    label: "Clos",
    labelle: "Clos",
  },
  {
    id: "Sans suite",
    value: "Sans suite",
    label: "Sans suite",
    labelle: "Sans suite",
  },
  {
    id: "DIAG",
    value: "DIAG",
    label: "DIAG",
    labelle: "DIAG",
  },
  {
    id: "Stand BY",
    value: "Stand BY",
    label: "Stand BY",
    labelle: "Stand BY",
  },
];
